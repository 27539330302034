<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->

<template>
  <v-container
    id="iiotiiotPciPassthroughContainer" class="mt-5 ml-1">
    <v-form
      id="iiotPciPassthroughPageForm"
      ref="pciPassthrough"
    >
      <v-row>
        <v-col
          cols="12"
          lg="8"
          class="pl-0 pt-0 pr-5">
          <span>{{ $t("workloadVersion.versionPciPassthrough").toUpperCase() }}</span> <br/>
          <pci-passthrough
            v-if="version && version.workloadProperties"
            :version="version"
            ref="pciPassthroughComponent"
            :prop="version.workloadProperties.PCI_passthrough"
            @submit-event="triggerSubmit"
            :isReleased="isReleased"
          />
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>

import PciPassthrough from '../dockerVmComponents/PCIPassthrough/PCIPassthrough.vue';

export default {
  components: { PciPassthrough },

  props: {
    version: {
      type: Object,
    },
    isReleased: {
      type: Boolean,
    },
  },
  methods: {
    triggerSubmit() {
      this.$emit('submit-event');
    },
  },

};
</script>
