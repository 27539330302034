<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->

<template>
  <v-container
    id="iiotPCIPassthroughContainer"
    data-cy="iiotPCIPassthroughContainer"
    class="mt-5 mb-5">
    <v-form
      id="iiotPCIPassthroughForm"
      data-cy="iiotPCIPassthroughForm"
      ref="pciPassthroughForm">
      <v-row v-for="(variable, index) in prop"
        :key="index">
        <v-col
          cols="11"
          class="pa-0">
          <v-row class="ma-0">
            <v-text-field
            :id="`iiotPCIPassthroughInput_${index}`"
            :data-cy="`iiotPCIPassthroughInput_${index}`"
            :label="$t('workloadVersion.pciPassthrough')"
            v-model="prop[index]"
            validate-on-blur
            @keydown.enter.prevent="triggerSubmit"
            :rules="[rules.required, rules.pattern, rules.duplicate]"
            :disabled="isReleased"
          />
          </v-row>
        </v-col>
        <v-col
          cols="1"
          class="mt-4 pa-0 pl-3">
          <v-btn
            :id="`iiotPCIPassthroughRemoveButton_${index}`"
            :data-cy="`iiotPCIPassthroughRemoveButton_${index}`"
            fab
            x-small
            dark
            class="primary-tttech-background-btn"
            @click="removePCIPassthrough(index)"
            :disabled="isReleased"
          >
            <v-icon dark>
              mdi-minus
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="isFormValid">
        <div class="mb-1 ml-1">
          <v-btn
            id="iiotPCIPassthroughNewButton"
            data-cy="iiotPCIPassthroughNewButton"
            fab
            x-small
            dark
            class="primary-tttech-background-btn"
            @click="addNewPCIPassthrough()"
            :disabled="isReleased"
          >
            <v-icon dark>
              mdi-plus
            </v-icon>
          </v-btn>
        </div>
        <span class="mt-1 ml-3">
          {{this.$t('workloadVersion.newPCIPassthrough')}}
        </span>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { VALIDATION_REGEX } from '@/constants';

export default {
  data: () => ({
    VALIDATION_REGEX,
    isFormValid: false,
  }),
  props: {
    prop: {
      type: Array,
    },
    isReleased: {
      type: Boolean,
    },
    version: {
      type: Object,
    },
  },
  computed: {
    rules() {
      return {
        required: (value) => !!value || this.$t('workloadVersion.required'),
        pattern: (value) => VALIDATION_REGEX.PCI_PASSTHROUGH.test(value) || this.$t('workloadVersion.pciPassthroughPatternValidation'),
        duplicate: (value) => {
          const found = this.prop
            .filter((element) => element === value);
          if (found.length > 1) {
            return false || this.$t('workloadVersion.pciPassthroughDuplicateValidation');
          }
          return true;
        },
      };
    },
  },
  methods: {
    addNewPCIPassthrough() {
      this.$refs.pciPassthroughForm.$el.dispatchEvent(new Event('input'));
      this.prop.push('');
    },
    removePCIPassthrough(index) {
      this.prop.splice(index, 1);
      this.$refs.pciPassthroughForm.$el.dispatchEvent(new Event('input'));
    },
    validationForm() {
      return this.$refs.pciPassthroughForm ? this.$refs.pciPassthroughForm.validate() : false;
    },
    triggerSubmit() {
      if (this.isFormValid) {
        this.$emit('submit-event');
      }
    },
  },
  mounted() {
    this.isFormValid = this.validationForm();
  },
  updated() {
    this.isFormValid = this.validationForm();
  },
};
</script>
