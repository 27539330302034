<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->

<template>
  <div
    id="iiotUploadFileVm"
    data-cy="iiotUploadFileVmContainer">
    <v-form
      id="iiotUploadFileVmForm"
      data-cy="iiotUploadFileVmForm"
      ref="uploadFileVmForm">
      <v-col>
        <v-row>
          <span>{{ $t('workloadVersion.vmTitle') }}</span>
        </v-row>
        <v-row>
          <v-col cols="11" class="pa-2">
            <v-tooltip
              top
            >
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <v-text-field
                    id="iiotUploadFileVmImageTextField"
                    v-model="imageFile"
                    :label="$t('workloadVersion.vmImageLabel')"
                    :placeholder="$t('workloadVersion.imagePlaceholder')"
                    :rules="[rules.required]"
                    class="isRequired"
                    disabled
                    required
                  />
                </div>
              </template>
              <span>
                {{ version.workloadProperties.snapshot.enabled === true ?
                $t('workloadVersion.snapshotEnabledVmTooltip')
                : $t('workloadVersion.snapshotDisabledVmTooltip')}}
              </span>
            </v-tooltip>
          </v-col>
          <v-col cols="1">
            <v-btn
              id="iiotUploadFileVmBtn"
              small
              light
              fab
              color="primary"
              @click="$refs.uploadFileVmImageInput.click()"
              :disabled="isReleased"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon
                    class="white--text"
                    v-on="on"
                  >
                    arrow_upward
                  </v-icon>
                </template>
                <span>{{ $t('workloadVersion.uploadBtnTooltip') }}</span>
              </v-tooltip>
            </v-btn>
            <input
              id="iiotUploadFileVmImageInput"
              ref="uploadFileVmImageInput"
              type="file"
              style="display:none"
              :accept="version.workloadProperties.snapshot.enabled === true
              ? allowedExtensionsSnaphotEnabledImage : allowedExtensionsSnaphotDisabledImage"
              @click="clearInput"
              @change="importFile($event, 'image',
              version.workloadProperties.snapshot.enabled === true
              ? allowedExtensionsSnaphotEnabledImage
              : allowedExtensionsSnaphotDisabledImage)"
              :disabled="isReleased"
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="11" class="pa-2">
            <v-text-field
              id="iiotUploadFileVmXmlTextField"
              v-model="xmlFile"
              :label="$t('workloadVersion.vmXmlLabel')"
              :placeholder="$t('workloadVersion.xmlPlaceholder')"
              :rules="[rules.required]"
              disabled
              required
            />
          </v-col>
          <v-col cols="1">
            <v-btn
              id="iiotUploadFileVmXmlBtn"
              light
              small
              fab
              @click="$refs.uploadFileVmXmlInput.click()"
              color="primary"
              :disabled="isReleased"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon
                    class="white--text"
                    v-on="on"
                  >
                    arrow_upward
                  </v-icon>
                </template>
                <span>{{ $t('workloadVersion.uploadBtnTooltip') }}</span>
              </v-tooltip>
            </v-btn>
            <input
              id="iiotUploadFileVmXmlInput"
              ref="uploadFileVmXmlInput"
              type="file"
              style="display:none"
              :accept="allowedExtensionsForVmXml"
              @click="clearInput"
              @change="importFile($event, 'xml', allowedExtensionsForVmXml)"
              :disabled="isReleased"
            >
          </v-col>
        </v-row>
      </v-col>
    </v-form>
  </div>
</template>

<script>

export default {
  data: () => ({
    allowedExtensionsSnaphotEnabledImage: ['.qcow2', '.zip', '.qcow2.gz'],
    allowedExtensionsSnaphotDisabledImage: ['.img', '.raw', '.vhdx', '.zip', '.gz', '.qcow2'],
    allowedExtensionsForVmXml: ['.xml'],
    imageFile: '',
    xmlFile: '',
    workloadId: '',
  }),
  props: {
    version: {
      type: Object,
    },
    isReleased: {
      type: Boolean,
    },
  },
  computed: {
    rules() {
      return {
        required: (value) => !!value || this.$t('workloadVersion.required'),
      };
    },
  },
  watch: {
    version() {
      this.$nextTick(() => {
        this.setFiles();
      });
    },
  },
  created() {
    const route = window.location.pathname.split('/');
    this.workloadId = route[route.length - 4];
    if (this.workloadId === 'new') {
      this.version.files = [{}, {}];
      this.imageFile = '';
      this.xmlFile = '';
      return;
    }
    this.setFiles();
  },
  methods: {
    _formatUploadedFiles(files) {
      const first = files[1];
      // eslint-disable-next-line no-nested-ternary
      files.sort((x, y) => (x === first ? -1 : y === first ? 1 : 0));
      return files;
    },
    setFiles() {
      if (this.version.files && this.version.files.length > 0 && this.version.files[0].fieldName === 'file2') {
        this.version.files = this._formatUploadedFiles(this.version.files);
      }
      this.imageFile = this.version.files && this.version.files.length > 0 && this.version.files[0] && this.version.files[0].originalName ? this.version.files[0].originalName : '';
      this.xmlFile = this.version.files && this.version.files.length > 0 && this.version.files[1] && this.version.files[1].originalName ? this.version.files[1].originalName : '';
    },
    importFile(e, type, extensions) {
      this.checkFile(e, type, extensions);
    },
    checkFile(e, type, extensions) {
      const file = e.target.files[0];
      const fileSize = file.size / 1024 / 1024;
      if (file && file.name && !extensions) {
        const obj = {
          originalName: file.name,
          fieldName: type === 'image' ? 'file1' : 'file2',
        };
        if (type === 'image') {
          this.version.files = [
            obj,
            this.version.files[1],
          ];
          this.imageFile = file.name;
          this.$store.dispatch('workloads/save_uploaded_file', file);
          this.$refs.uploadFileVmForm.$el.dispatchEvent(new Event('input'));
        } else {
          this.version.files = [
            this.version.files[0],
            obj,
          ];
          this.xmlFile = file.name;
          this.$store.dispatch('workloads/save_uploaded_xml_file', file);
          this.$refs.uploadFileVmForm.$el.dispatchEvent(new Event('input'));
        }
      } else if (file && file.name && extensions) {
        if (fileSize <= 10 && file.type === 'application/x-raw-disk-image' && extensions.find((ext) => file.name.toLowerCase().endsWith(ext))) {
          this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
            text: 'uploadFile.smallFile',
            color: 'red',
            showClose: true,
          });
        } else if (fileSize === 0) {
          this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
            text: 'uploadFile.emptyFile',
            color: 'red',
            showClose: true,
          });
        } else if (fileSize >= 0
          // eslint-disable-next-line max-len
          && extensions.find((ext) => file.name.toLowerCase().endsWith(ext))) {
          const obj = {
            originalName: file.name,
            fieldName: type === 'image' ? 'file1' : 'file2',
          };
          if (type === 'image') {
            this.version.files = [
              obj,
              this.version.files[1],
            ];
            this.imageFile = file.name;
            this.$store.dispatch('workloads/save_uploaded_file', file);
            this.$refs.uploadFileVmForm.$el.dispatchEvent(new Event('input'));
          } else {
            this.version.files = [
              this.version.files[0],
              obj,
            ];
            this.xmlFile = file.name;
            this.$store.dispatch('workloads/save_uploaded_xml_file', file);
            this.$refs.uploadFileVmForm.$el.dispatchEvent(new Event('input'));
          }
        } else {
          const extensionsArray = extensions.slice();
          if (extensionsArray.length !== 1) {
            const a = extensionsArray[extensionsArray.length - 2] + this.$t('uploadFile.or') + extensionsArray[extensionsArray.length - 1];
            extensionsArray.splice(-2, 2);
            extensionsArray.push(a);
          }
          this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
            text: 'uploadFile.wrongFileExtension',
            textParameters: extensionsArray.join(', '),
            color: 'red',
            showClose: true,
          });
        }
      }
    },
    clearInput(e) {
      e.target.value = '';
    },
  },
};
</script>
<style>
  .v-text-field.v-input--is-disabled .v-input__control > .v-text-field__details > .v-messages {
    color: var(--v-error-base) !important;
  }
</style>
