<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->

<template>
  <v-container
    v-if="version.workloadProperties || version.auth_credentials"
    id="iiotFromRegistryContainer"
    class="mb-5 pa-0">
    <v-form
      id="iiotFromRegistryForm"
      ref="fromRegistryForm">
      <div>
        <v-text-field
          id="iiotFromRegistryImagePath"
          v-model="version.dockerFilePath"
          :label="$t('workloadVersion.imagePathLabel')"
          :rules="[rules.dockerImagePath, rules.required]"
          :disabled="isReleased"
        />
        <v-text-field
          v-if="version.workloadProperties && version.workloadProperties.auth_credentials"
          id="iiotFromRegistryUsername"
          v-model="version.workloadProperties.auth_credentials.username"
          :label="$t('workloadVersion.usernameLabel')"
          :rules="[rules.dockerUsername, rules.dockerUsernameMinLength]"
          :maxlength="DOCKER_USERNAME_LENGTH.MAX_LENGTH"
          :minlength="DOCKER_USERNAME_LENGTH.MIN_LENGTH"
          :disabled="isReleased"
        />
        <v-text-field
          v-if="version.auth_credentials"
          id="iiotFromRegistryUsername"
          v-model="version.auth_credentials.username"
          :label="$t('workloadVersion.usernameLabel')"
          :rules="[rules.dockerUsername, rules.dockerComposeUsernameMinLength]"
          :maxlength="DOCKER_USERNAME_LENGTH.MAX_LENGTH"
          :minlength="DOCKER_USERNAME_LENGTH.MIN_LENGTH"
          :disabled="isReleased"
        />
        <v-text-field
          v-if="version.workloadProperties && version.workloadProperties.auth_credentials"
          id="iiotFromRegistryPassword"
          :append-icon="icon"
          v-model="version.workloadProperties.auth_credentials.password"
          :rules="[rules.dockerPassword, rules.dockerPasswordMinLength]"
          :label="$t('workloadVersion.passwordLabel')"
          :maxlength="DOCKER_PASSWORD.MAX_LENGTH"
          :minlength="DOCKER_PASSWORD.MIN_LENGTH"
          @keyup="passwordChanged"
          @click:append="togglePassword"
          :disabled="isReleased"
          :type="type"
        />
        <v-text-field
          v-if="version.auth_credentials"
          id="iiotFromRegistryPassword"
          :append-icon="icon"
          v-model="version.auth_credentials.password"
          :rules="[rules.dockerPassword, rules.dockerComposePasswordMinLength]"
          :label="$t('workloadVersion.passwordLabel')"
          :maxlength="DOCKER_PASSWORD.MAX_LENGTH"
          :minlength="DOCKER_PASSWORD.MIN_LENGTH"
          @keyup="passwordChanged"
          @click:append="togglePassword"
          :disabled="isReleased"
          :type="type"
        />
      </div>
    </v-form>
  </v-container>
</template>

<script>
import { VALIDATION_REGEX, DOCKER_PASSWORD, DOCKER_USERNAME_LENGTH } from '@/constants';

export default {
  data: () => ({
    type: 'text',
    hidePassword: true,
    VALIDATION_REGEX,
    DOCKER_PASSWORD,
    DOCKER_USERNAME_LENGTH,
  }),
  props: {
    version: {
      type: Object,
    },
    isReleased: {
      type: Boolean,
    },
  },
  computed: {
    rules() {
      return {
        required: (value) => !!value || this.$t('workloadVersion.required'),
        // Wrong format for a Docker Image Path
        dockerImagePath: (value) => (value.length === 0 || VALIDATION_REGEX.DOCKER_IMAGE_PATH.test(value)) || this.$t('workloadVersion.dockerImagePathPattern'),
        dockerUsername: (value) => VALIDATION_REGEX.DOCKER_USERNAME.test(value) || this.$t('workloadVersion.dockerUsernamePattern'),
        dockerPassword: (value) => VALIDATION_REGEX.NO_CONTROL_CHARACTERS.test(value) || this.$t('workloadVersion.dockerPasswordPattern'),
        dockerPasswordMinLength: () => (this.version.workloadProperties.auth_credentials.password ? ((this.version.workloadProperties.auth_credentials.password.length === 0 || this.version.workloadProperties.auth_credentials.password.length >= DOCKER_PASSWORD.MIN_LENGTH) || this.$t('workloadVersion.dockerPasswordLength')) : true),
        dockerUsernameMinLength: () => (this.version.workloadProperties.auth_credentials.username ? ((this.version.workloadProperties.auth_credentials.username.length === 0 || this.version.workloadProperties.auth_credentials.username.length >= DOCKER_USERNAME_LENGTH.MIN_LENGTH) || this.$t('workloadVersion.dockerUsernameLength')) : true),
        dockerComposePasswordMinLength: () => (this.version.auth_credentials.password ? ((this.version.auth_credentials.password.length === 0 || this.version.auth_credentials.password.length >= DOCKER_PASSWORD.MIN_LENGTH) || this.$t('workloadVersion.dockerPasswordLength')) : true),
        dockerComposeUsernameMinLength: () => (this.version.auth_credentials.username ? ((this.version.auth_credentials.username.length === 0 || this.version.auth_credentials.username.length >= DOCKER_USERNAME_LENGTH.MIN_LENGTH) || this.$t('workloadVersion.dockerUsernameLength')) : true),
      };
    },
    icon() {
      return this.hidePassword ? 'mdi-eye-off' : 'mdi-eye';
    },
  },
  methods: {
    togglePassword() {
      this.hidePassword = !this.hidePassword;
      this.setType();
    },
    setType() {
      this.type = this.hidePassword ? 'password' : 'text';
    },
    passwordChanged() {
      this.setType();
    },
  },
  mounted() {
    if ((this.version.workloadProperties && this.version.workloadProperties.auth_credentials && this.version.workloadProperties.auth_credentials.password && this.version.workloadProperties.auth_credentials.password !== '')
    || (this.version.auth_credentials && this.version.auth_credentials.password && this.version.auth_credentials.password !== '')) {
      this.hidePassword = true;
      this.type = 'password';
    }
  },
};
</script>
