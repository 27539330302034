<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->

<template>
  <div
    id="iiotUploadFileComposeContainer"
    data-cy="iiotUploadFileComposeContainer"
    class="mb-5">
    <v-form
      id="iiotUploadFileComposeForm"
      data-cy="iiotUploadFileComposeForm"
      ref="uploadFileComposeForm"
    >
      <v-col>
        <v-row>
          <v-col cols="10" class="pa-0">
            <v-text-field
              id="iiotUploadFileComposeTextField"
              data-cy="iiotUploadFileComposeTextField"
              v-model="composeFile"
              :label="$t('workloadVersion.composeLabel')"
              :placeholder="$t('workloadVersion.composePlaceholder')"
              :rules="[rules.required, rules.isComposeValid]"
              :class="{'isValid': getIsComposeFileValid, 'noComposeFile': !composeFile}"
              disabled
              required
              persistent-hint
              :hint="composeFile && getIsComposeFileValid
                ? $t('workloadVersion.validComposeFile')
                : $t('workloadVersion.dockerComposeInfoMessage')"
            />
          </v-col>
          <v-col cols="2">
            <v-btn
              id="iiotUploadFileComposeBtn"
              data-cy="iiotUploadFileComposeBtn"
              light
              color="primary"
              small
              fab
              @click="uploadFile()"
              :disabled="isReleased"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon
                    class="white--text"
                    v-on="on"
                  >
                    arrow_upward
                  </v-icon>
                </template>
                <span>{{ $t('workloadVersion.uploadBtnTooltip') }}</span>
              </v-tooltip>
            </v-btn>
            <input
              id="iiotUploadFileComposeInput"
              data-cy="iiotUploadFileComposeInput"
              ref="uploadFileComposeInput"
              type="file"
              style="display:none"
              :accept="allowedExtensionsForCompose"
              @click="clearInput"
              @change="importFile"
              :disabled="isReleased"
            >
          </v-col>
        </v-row>
      </v-col>
    </v-form>
    <v-card
      v-if="composeFile && !getIsComposeFileValid"
      id="iiotUploadFileComposeErrorMessage"
      data-cy="iiotUploadFileComposeErrorMessage"
      class="errorMessageCard mt-5"
      flat
    >
      <v-card-text class="errorMessageText text-left">
        <div v-html="errorMessage"/>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Logger from '@/utils/logger';

export default {
  data: () => ({
    allowedExtensionsForCompose: ['.yaml', '.yml'],
    composeFile: '',
    errorMessage: '',
  }),
  props: {
    version: {
      type: Object,
    },
    isReleased: {
      type: Boolean,
    },
  },
  watch: {
    version() {
      this.$nextTick(() => {
        this.composeFile = this.version.composeFile ? this.version.composeFile.originalName : '';
      });
    },
    getIsComposeFileValid() {
      this.$refs.uploadFileComposeForm.validate();
    },
  },
  computed: {
    rules() {
      return {
        required: (value) => !!value || this.$t('workloadVersion.dockerComposeInfoMessage'),
        isComposeValid: (value) => (!!value && this.getIsComposeFileValid) || this.$t('workloadVersion.invalidComposeFile'),
      };
    },
    getIsComposeFileValid() {
      return this.$store.getters['workloads/getIsComposeFileValid'];
    },
  },
  created() {
    const route = window.location.pathname.split('/');
    this.workloadId = route[route.length - 4];
    if (this.workloadId === 'new') {
      this.composeFile = '';
      return;
    }
    this.composeFile = this.version.composeFile ? this.version.composeFile.originalName : '';
  },
  methods: {
    clearInput(e) {
      e.target.value = '';
    },
    async importFile(e) {
      const file = e.target.files[0];
      const fileSize = file ? file.size / 1024 / 1024 : 0;
      const fd = new FormData();
      if (file && file.name) {
        if (!this.allowedExtensionsForCompose) {
          this.version.composeFile = {
            fieldName: 'file',
            originalName: file.name,
          };
          this.composeFile = file.name;
          try {
            fd.append('file', file);
            this.version.services = [];
            await this.$store.dispatch('workloads/verify_uploaded_compose_file', fd);
            this.$store.dispatch('workloads/remove_changed_docker_images', { removeAll: true });
            this.$store.dispatch('workloads/save_uploaded_compose_file', file);
            this.$store.dispatch('workloads/set_compose_file_flag', true);
            this.$refs.uploadFileComposeForm.$el.dispatchEvent(new Event('input'));
          } catch {
            this.$store.dispatch('workloads/set_compose_file_flag', false);
            this.$store.dispatch('workloads/remove_changed_docker_images', { removeAll: true });
            await this.$store.dispatch('workloads/verify_uploaded_compose_file_failed');
            this.$refs.uploadFileComposeForm.$el.dispatchEvent(new Event('input'));
          }
          return;
        }
        if (fileSize === 0) {
          this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
            text: 'uploadFile.emptyFile',
            color: 'red',
            showClose: true,
          });
          return;
        }
        if (fileSize >= 0
          // eslint-disable-next-line max-len
          && this.allowedExtensionsForCompose.find((ext) => file.name.toLowerCase().endsWith(ext))) {
          this.version.composeFile = {
            fieldName: 'file',
            originalName: file.name,
          };
          this.composeFile = file.name;
          try {
            fd.append('file', file);
            this.version.services = [];
            await this.$store.dispatch('workloads/verify_uploaded_compose_file', fd);
            this.$store.dispatch('workloads/save_uploaded_compose_file', file);
            this.$store.dispatch('workloads/remove_changed_docker_images', { removeAll: true });
            this.$store.dispatch('workloads/set_compose_file_flag', true);
            this.$refs.uploadFileComposeForm.$el.dispatchEvent(new Event('input'));
          } catch (error) {
            this.$store.dispatch('workloads/set_compose_file_flag', false);
            this.$refs.uploadFileComposeForm.validate();
            this.errorMessage = error.response && error.response.data && error.response.data[0] && error.response.data[0].errorCode ? this.$t(`errorMessages.${error.response.data[0].errorCode}`) : this.$t('errorMessages.nerve_unexp_server_001');
            await this.$store.dispatch('workloads/verify_uploaded_compose_file_failed');
            this.$store.dispatch('workloads/remove_changed_docker_images', { removeAll: true });
            this.$refs.uploadFileComposeForm.$el.dispatchEvent(new Event('input'));
          }
          return;
        }
        const extensionsArray = this.allowedExtensionsForCompose.slice();
        if (extensionsArray.length !== 1) {
          const a = extensionsArray[extensionsArray.length - 2] + this.$t('uploadFile.or') + extensionsArray[extensionsArray.length - 1];
          extensionsArray.splice(-2, 2);
          extensionsArray.push(a);
        }
        this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
          text: 'uploadFile.wrongFileExtension',
          textParameters: extensionsArray.join(', '),
          color: 'red',
          showClose: true,
        });
      }
    },
    uploadFile() {
      if (this.composeFile) {
        if (window.localStorage.getItem('userDetails')
          && JSON.parse(localStorage.getItem('userDetails')).confirmDialog?.uploadComposeYAMLfile) {
          this.triggerInput();
          return;
        }
        this.$store.dispatch('utils/_api_request_handler/show_confirm_dialog', {
          title: 'uploadFile.confirmDialogTitle',
          subTitle: 'uploadFile.confirmDialogSubtitle',
          callback: async () => {
            this.triggerInput();
          },
          dontAskMeAgainCallback: async () => {
            const userDetails = JSON.parse(localStorage.getItem('userDetails'));
            userDetails.confirmDialog = userDetails.confirmDialog || {};
            userDetails.confirmDialog.uploadComposeYAMLfile = true;
            window.localStorage.setItem('userDetails', JSON.stringify(userDetails));
          },
        });
        return;
      }
      this.triggerInput();
    },
    triggerInput() {
      try {
        this.$refs.uploadFileComposeInput.click();
      } catch (e) {
        Logger.error(e);
      }
    },
  },
};
</script>
<style>
  .isValid.v-input--is-label-active.v-input--is-dirty.v-input--is-disabled
  .v-input__control > .v-text-field__details > .v-messages {
    color: var(--v-success-base) !important;
  }
  .noComposeFile.v-input--is-disabled .v-input__control > .v-text-field__details > .v-messages {
    color: rgba(0, 0, 0, 0.38) !important;
  }
  .errorMessageCard {
    background-color: rgba(251, 252, 252, 1) !important;
    border: solid 1px #f0f3f5 !important;
    width: 100%;
  }
  .errorMessageText {
    color: black !important;
    font-size: 16px !important;
  }
</style>
