<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->

<template>
  <v-container
    id="iiotVmContainer"
    fill-height
    fluid
    class="pl-0 pt-0"
  >
    <v-row>
      <v-col>
        <v-row>
          <v-col
            cols="12"
            lg="11">
            <div v-if="!isWorkloadEmpty" class="title">
              <h1>
                {{ isNewWl  ? $t('workloadVersion.newWl') : (isUpdate ?
                $t('workloadVersion.editWl') :  isCloneWl ?
                $t('workloadVersion.cloneWl') :  $t('workloadVersion.title')) }}
              </h1>
              <v-divider />
            </div>
          </v-col>
          <v-col
            v-if="version.errorMessage && version.errorMessage !== 'nerve_workload_cancelled'"
            cols="12"
            lg="8">
            <div>
              <v-alert
                icon="mdi-alert"
                border="left"
                color="red"
                text
                class="vm-alert"
              >
              {{ this.$t('errorMessages.WORKLOAD_VM_IMG_CONVERSION_ERROR') +
              ': "' + this.version.errorMessage + '"' }}
              </v-alert>
            </div>
          </v-col>
        </v-row>
        <v-form
          v-if="!isWorkloadEmpty && isVersionReady"
          id="iiotVmForm"
          data-cy="iiotVmForm"
          ref="form"
          @submit.prevent="submitForm"
        >
          <div class="pl-0 mb-10">
            <v-row>
              <v-col>
                <v-tabs
                  v-model="tab"
                  class="tab-text-transform"
                  next-icon="mdi-arrow-right-bold-box-outline"
                  prev-icon="mdi-arrow-left-bold-box-outline"
                  show-arrows>
                  <v-tab
                    id="iiotVmBasicTab"
                    data-cy="iiotVmBasicTab"
                    autofocus
                    :key="basic"
                    @click="tabChanged(0)"
                  >
                    <h4 v-if="isBasicFormValid">{{ $t('workloadVersion.basic') }}</h4>
                    <h4
                      v-if="!isBasicFormValid"
                      class="required">
                      {{ $t('workloadVersion.requiredBasic') }}
                    </h4>
                  </v-tab>
                  <v-tab
                    id="iiotVmNetworkingTab"
                    data-cy="iiotVmNetworkingTab"
                    :key="networking"
                    type="vm"
                    :version="version"
                    @click="tabChanged(1)"
                  >
                    <h4 v-if="isNetworkingFormValid">{{ $t('workloadVersion.networking') }}</h4>
                    <h4
                      v-if="!isNetworkingFormValid"
                      class="required">
                      {{ $t('workloadVersion.requiredNetworking') }}
                    </h4>
                  </v-tab>
                  <v-tab
                    id="iiotVmDisksTab"
                    data-cy="iiotVmDisksTab"
                    :key="disks"
                    @click="tabChanged(2)"
                  >
                    <h4 v-if="isDiskFormValid">{{ $t('workloadVersion.disks') }}</h4>
                    <h4
                      v-if="!isDiskFormValid"
                      class="required"
                    >{{ $t('workloadVersion.requiredDisks') }}</h4>
                  </v-tab>
                  <v-tab
                    id="iiotVmPciPassthroughTab"
                    data-cy="iiotVmPciPassthroughTab"
                    :key="pciPassthrough"
                    @click="tabChanged(3)"
                  >
                    <h4 v-if="isPciPassthroughFormValid">
                      {{ $t('workloadVersion.versionPciPassthrough') }}
                    </h4>
                    <h4
                      v-if="!isPciPassthroughFormValid"
                      class="required">
                      {{ $t('workloadVersion.requiredVersionPciPassthrough') }}
                    </h4>
                  </v-tab>

                  <v-tab
                    id="iiotVmRemoteConnectionTab"
                    data-cy="iiotVmRemoteConnectionTab"
                    :key="remoteConnections"
                    @click="tabChanged(4)"
                  >
                    <h4>{{ $t('workloadVersion.remoteConnections') }}</h4>
                  </v-tab>
                </v-tabs>
                <v-divider />
              </v-col>
            </v-row>
            <v-tabs-items
              v-model="tab">
              <v-tab-item
                eager
                :key="basic">
                <basic
                  ref="basic"
                  type="vm"
                  :version="version"
                  @submit-event="submitEventHandler"
                  :isReleased="isReleased"
                />
              </v-tab-item>
              <v-tab-item
                eager
                :key="networking">
                <networking
                  ref="networking"
                  :version="version"
                  type="vm"
                  @submit-event="submitEventHandler"
                  :isReleased="isReleased"
                />
              </v-tab-item>
              <v-tab-item
                eager
                :key="disks">
                <v-col
                  class="mt-5 ml-1"
                  cols="12"
                  lg="6"
                >
                  <span>
                    {{ $t('workloadVersion.diskTitle') }}
                  </span>
                  <disk
                    v-if="version.workloadProperties"
                    ref="disk"
                    :prop="version.workloadProperties.data_disks"
                    :version="version"
                    :isReleased="isReleased"
                  />
                </v-col>
              </v-tab-item>
              <v-tab-item
                eager
                :key="pciPassthrough">
                <v-col
                  cols="12"
                  lg="4"
                >
                  <pci-passthrough
                    ref="pciPassthrough"
                    :version="version"
                    @submit-event="submitEventHandler"
                    :isReleased="isReleased"
                  />
                </v-col>
              </v-tab-item>
              <v-tab-item
                eager
                :key="remoteConnections">
                <v-col
                  cols="12"
                  lg="4">
                  <remote-connection-list
                    id="iiotVmList"
                    :model="version"
                    :version-type="'vm'"
                  />
                </v-col>
              </v-tab-item>
            </v-tabs-items>
          </div>
          <div class="fixed-buttons">
            <nerve-button
              id="iiotVmCancelButton"
              :text="$t('baseForm.cancelBtn')"
              type-of-btn="cancel"
              size="normal"
              class="ml-20 mr-5"
              @click-event="cancel()"
            />
            <nerve-button
              v-if="(!isUpdate && !canAccess('UI_WORKLOAD:VERSION_EDIT'))
              || (isUpdate && canAccess('UI_WORKLOAD:VERSION_EDIT'))
              || (!isUpdate && canAccess('UI_WORKLOAD:VERSION_CREATE'))"
              id="iiotVmSaveButton"
              :disabled="!isSaveEnabled || !isValid"
              :text="$t('baseForm.saveBtn')"
              type-of-btn="action"
              size="normal"
              type="submit"
            />
          </div>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>
<!-- eslint-disable no-unused-expressions -->
<script>
import { NerveButton } from 'nerve-ui-components';
import RemoteConnectionList from '@/components/remote-connection/RemoteConnectionList.vue';
import Basic from '@/components/workloads/workloadVersionComponents/Basic.vue';
import Networking from '@/components/workloads/workloadVersionComponents/Networking.vue';
import PciPassthrough from '@/components/workloads/workloadVersionComponents/PCIPassthrough.vue';
import Disk from '@/components/workloads/dockerVmComponents/dataDisk/Disk.vue';
import Logger from '@/utils/logger';

export default {
  components: {
    RemoteConnectionList,
    Basic,
    Networking,
    PciPassthrough,
    Disk,
    NerveButton,
  },
  props: {
    version: {
      type: Object,
    },
  },
  data: () => ({
    isUpdate: false,
    tab: null,
    basic: 0,
    networking: 1,
    disks: 2,
    pciPassthrough: 3,
    remoteConnections: 4,
    workloadId: '',
    // isNextBackButtonsVisible: true,
    isBasicFormValid: true,
    isPciPassthroughFormValid: true,
    isDiskFormValid: true,
    isNetworkingFormValid: true,
    availableTabs: [0, 1, 2, 3, 4],
    createAvailableTabs: [0, 1, 2, 3],
    versionId: '',
    isWorkloadEmpty: false,
    isRequestInProgress: false,
    numberOfTabs: 0,
    isReleased: false,
    isValid: false,
    isVersionReady: false,
    isNewWl: false,
    isCloneWl: false,
  }),
  watch: {
    async isSaveEnabled(newValue) {
      if (newValue) {
        this.isValid = await this.isVmFormValid();
      }
    },
    getFormChangedFlag() {
      this.formChanged();
    },
  },
  async created() {
    try {
      const route = window.location.pathname.split('/');
      this.versionId = route[route.length - 2];
      if (this.versionId === 'clone') {
        this.isCloneWl = true;
      }
      this.isUpdate = this.versionId !== 'new' && this.versionId !== 'clone';
      this.workloadId = route[route.length - 4];
      this.numberOfTabs = this.isUpdate ? 4 : 3;
      const tab = Number(this.$route.query.tab);
      const tabs = this.isUpdate ? this.availableTabs : this.createAvailableTabs;
      if (tabs.includes(tab)) {
        this.tabChanged(Number(this.$route.query.tab));
      } else {
        this.tabChanged(0);
      }
      this.version.released = false;
      if (this.versionId !== 'clone') {
        await this.$store.dispatch('workloads/get_workload_by_id', this.workloadId);
      } else {
        if (Object.keys(this.version).length === 0 && this.version.constructor === Object) {
          this.cancel();
        }
        if (!this.version.workloadProperties) {
          this.cancel();
        }

        this.isVersionReady = true;
        this.$store.dispatch('workloads/set_save_enabled', this.version);

        // Add listeners for changes on all forms
        this.$nextTick(() => {
          this.addListeners();
        });
        return;
      }
      this.workload.type = 'vm';
      await this.$store.dispatch('workloads/get_version_by_id', { id: this.versionId, isUpdate: this.isUpdate });
      this.isVersionReady = true;
      if (this.versionId === 'new') {
        this.version.released = false;
        this.isNewWl = true;
      }
      this.isReleased = this.version.released;

      // Add listeners for changes on all forms
      this.$nextTick(() => {
        this.addListeners();
      });

      if (this.getNameAndDesc.name === '' && this.workload.name === '') {
        this.cancel();
      }
      if (this.getNameAndDesc.name === '' && this.workload.name !== '') {
        return;
      }
      if (this.getNameAndDesc.name !== '' && this.workload.name === '') {
        this.workload.name = this.getNameAndDesc.name;
        this.workload.description = this.getNameAndDesc.description;
        return;
      }
      if (this.workload.name !== this.getNameAndDesc.name && this.workload.name !== '') {
        this.workload.name = this.getNameAndDesc.name;
        this.workload.description = this.getNameAndDesc.description;
      }
    } catch (e) {
      Logger.error(e);
    }
  },

  computed: {
    workload() {
      return this.$store.getters['workloads/getWorkload'];
    },
    getNameAndDesc() {
      return this.$store.getters['workloads/getNameAndDesc'];
    },
    isSaveEnabled() {
      return this.$store.getters['workloads/isSaveEnabled'];
    },
    getFormChangedFlag() {
      return this.$store.getters['workloads/getFormChangedFlag'];
    },
  },
  async updated() {
    await this.$store.dispatch('remote-connection/fetch', this.version);
  },
  beforeDestroy() {
    this.$store.dispatch('workloads/disable_save_button');
  },
  methods: {
    async submitForm() {
      if (!this.isVmFormValid()) {
        return;
      }
      this.isRequestInProgress = true;

      const showSnapshotWarning = this.isZipArchive() && this.isSnapshotEnabled();

      if (this.version.released && !showSnapshotWarning) {
        this.$store.dispatch('utils/_api_request_handler/show_confirm_dialog', {
          title: 'workloadVersion.markAsReleasedMessageTitle',
          subTitle: 'workloadVersion.markAsReleasedMessageText',
          callback: async () => {
            this.createWorkload();
          },
        });
      } else if (!this.version.released && showSnapshotWarning) {
        // Show confirmation dialog for snapshots
        this.$store.dispatch('utils/_api_request_handler/show_confirm_dialog', {
          title: 'workloadVersion.snapshotEnabledAndZipArchiveTitle',
          subTitle: 'workloadVersion.snapshotEnabledAndZipArchiveText',
          callback: async () => {
            this.createWorkload();
          },
        });
      } else if (this.version.released && showSnapshotWarning) {
        // Confirmation dialog contains both messages(released and snapshot)
        this.$store.dispatch('utils/_api_request_handler/show_confirm_dialog', {
          title: 'workloadVersion.confirmSaveSnapshotAndZipAndReleasedTitle',
          subTitle: 'workloadVersion.confirmSaveSnapshotAndZipAndReleasedText',
          callback: async () => {
            this.createWorkload();
          },
        });
      } else {
        this.createWorkload();
      }
    },

    async resetUploadedFiles() {
      await this.$store.dispatch('workloads/save_uploaded_file', {});
      await this.$store.dispatch('workloads/save_uploaded_xml_file', {});
    },
    async isVmFormValid() {
      this.isBasicFormValid = await this.$refs.basic.$refs.basicForm.validate()
        && await this.$refs.basic.$refs.limitMemory.$refs.limitMemoryForm.validate()
        && (this.canAccess('UI_WORKLOAD:SNAPSHOT') && this.$refs.basic.$refs.snapshot.$refs.limitMemory
          ? await this.$refs.basic.$refs.snapshot.$refs.limitMemory.$refs.limitMemoryForm.validate()
          : true)
        && await this.$refs.basic.$refs.versionUploadImage.$refs.uploadFileVm
          .$refs.uploadFileVmForm.validate();
      this.isNetworkingFormValid = await this.isNetworkingValid();
      this.isDiskFormValid = await this.$refs.disk.$refs.diskForm.validate();
      this.isPciPassthroughFormValid = await this.$refs.pciPassthrough.$refs.pciPassthroughComponent
        .$refs.pciPassthroughForm.validate();
      return this.isBasicFormValid && this.isNetworkingFormValid
        && this.isDiskFormValid && this.isPciPassthroughFormValid;
    },
    async isNetworkingValid() {
      if (this.$refs.networking.$refs.networkInterface.$refs.portsForm) {
        return await this.isPortValid()
        && this.$refs.networking.$refs.networkInterface.$refs.networkInterfaceForm.validate();
      }
      return this.$refs.networking.$refs.networkInterface.$refs.networkInterfaceForm.validate();
    },
    async isPortValid() {
      const isPortValidate = [];
      this.$refs.networking.$refs.networkInterface.$refs.portsForm.forEach(
        (port) => isPortValidate.push(port.$refs.portsForm.validate()),
      );
      const allTrue = !isPortValidate.some((x) => x === false);
      return allTrue;
    },
    async tabChanged(tab) {
      this.tab = tab;
      await this.$router.push({
        name: 'Add edit workload version',
        query: {
          tab,
        },
      }).catch(() => {});
    },
    cancel() {
      this.$store.dispatch('workloads/save_uploaded_file', {});
      this.$store.dispatch('workloads/save_uploaded_xml_file', {});
      this.$router.push({ name: 'Add edit workload', params: { id: this.workloadId, type: 'vm' } });
    },
    submitEventHandler() {
      this.submitForm();
    },
    async createWorkload() {
      if (this.version.workloadProperties.libvirt_networks.find((network) => network.type === 'NAT' && !network.port_forwards)) {
        this.version.workloadProperties.libvirt_networks.find((network) => network.type === 'NAT' && !network.port_forwards).port_forwards = {};
      }
      if (this.version.workloadProperties.libvirt_networks.find((network) => network.type === 'Bridged' && network.port_forwards)) {
        delete this.version.workloadProperties.libvirt_networks.find((network) => network.type === 'Bridged' && network.port_forwards).port_forwards;
      }
      if (!this.workload.versions.length) {
        this.version.files = {
          0: {
            originalName: this.version.files[0].originalName,
          },
          1: {
            originalName: this.version.files[1].originalName,
          },
        };
      }
      const isAddVersionOnExistingWl = !this.isUpdate && this.version._id;
      if (isAddVersionOnExistingWl) {
        delete this.version._id;
      }
      const fd = new FormData();
      this.version.dockerFileOption = '';
      this.workload.versions = [this.version];
      this.workload.type = 'vm';
      fd.append('data', JSON.stringify(this.workload));
      fd.append('file1', this.$store.getters['workloads/getUploadedFile']);
      fd.append('file2', this.$store.getters['workloads/getUploadedXmlFile']);
      try {
        if (!this.workload._id) {
          await this.$store.dispatch('workloads/create_workload', fd);
        } else {
          await this.$store.dispatch('workloads/update_version', fd);
        }
        this.resetUploadedFiles();
        this.$store.dispatch('utils/_api_request_handler/close_progress_bar');
      } catch (e) {
        this.$store.dispatch('utils/_api_request_handler/close_progress_bar');
        return;
      }

      if (this.workloadId === 'new') {
        await this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
          text: 'workloadVersion.successfullyCreated',
          color: 'green',
          showClose: true,
        });
        this.$router.push({
          name: 'Workloads',
        });
        return;
      }
      await this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
        text: 'workloadDetail.successfullyUpdate',
        color: 'green',
        showClose: true,
      });
      this.$router.push({
        name: 'Add edit workload',
        params: { id: this.workloadId, type: 'vm' },
      });
    },
    /**
     * Checks if upload vm file is zip archive
     */
    isZipArchive() {
      const { files } = this.version;

      if (Array.isArray(files) && files.length) {
        const found = files.find((file) => {
          // File from DB
          if (file.type === '.zip') {
            return file;
          }
          // User select a new file
          if (file.originalName && file.originalName.substring(file.originalName.lastIndexOf('.')) === '.zip') {
            return file;
          }
          return file;
        });
        return !!found;
      }

      if (files instanceof Object) {
        const values = Object.values(files);
        if (!values.length) {
          return false;
        }
        const found = values.find((file) => file.originalName.substring(file.originalName.lastIndexOf('.')) === '.zip');
        return !!found;
      }
      return false;
    },
    isSnapshotEnabled() {
      if (!this.version || !this.version.workloadProperties
      || !this.version.workloadProperties.snapshot) {
        return false;
      }
      return this.version.workloadProperties.snapshot.enabled;
    },
    async formChanged() {
      this.isValid = await this.isVmFormValid();
      if (this.isValid) {
        this.$store.dispatch('workloads/set_save_enabled', this.version);
        return;
      }
      this.$store.dispatch('workloads/disable_save_button');
    },
    addListeners() {
      this.$refs.basic?.$refs.basicForm?.$el.addEventListener('input', () => {
        this.formChanged();
      });
      this.$refs.basic?.$refs.limitMemory?.$refs.limitMemoryForm?.$el.addEventListener('input', () => {
        this.formChanged();
      });
      this.$refs.basic?.$refs.snapshot?.$refs.snapshotForm?.$el.addEventListener('input', () => {
        this.$refs.basic?.$refs.snapshot?.$refs.limitMemory?.$refs.limitMemoryForm?.$el.addEventListener('input', () => {
          this.formChanged();
        });
        this.formChanged();
      });
      this.$refs.basic?.$refs.snapshot?.$refs.limitMemory?.$refs.limitMemoryForm?.$el.addEventListener('input', () => {
        this.formChanged();
      });
      this.$refs.basic?.$refs.versionUploadImage?.$refs.uploadFileVm?.$refs.uploadFileVmForm?.$el.addEventListener('input', () => {
        this.formChanged();
      });
      this.$refs.disk?.$refs.diskForm?.$el.addEventListener('input', () => {
        this.formChanged();
      });
      this.$refs.pciPassthrough?.$refs.pciPassthroughComponent?.$refs.pciPassthroughForm?.$el.addEventListener('input', () => {
        this.formChanged();
      });
      this.$refs.networking?.$refs.networkInterface?.$refs.networkInterfaceForm?.$el.addEventListener('input', () => {
        this.$refs.networking?.$refs.networkInterface?.$refs.portsForm?.forEach(
          (port) => port.$refs.portsForm?.$el.addEventListener('input', () => {
            this.formChanged();
          }),
        );
        this.formChanged();
      });
      this.$refs.networking?.$refs.networkInterface?.$refs.portsForm?.forEach(
        (port) => port.$refs.portsForm?.$el.addEventListener('input', () => {
          this.formChanged();
        }),
      );
    },
  },
};
</script>

<style scoped>
  .fixed-buttons {
    position: fixed !important;
    bottom: 10px;
    background: white;
    z-index: 10;
    padding-bottom: 10px;
    padding-right: 10px;
  }
  .required {
    color: #701825;
  }
  .vm-alert {
    font-size: 14px;
  }
</style>
