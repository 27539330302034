<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->

<template>
  <v-container
    v-if="version && version.workloadProperties"
    id="iiotResourcesContainer" class=" iiotResourcesRow mt-8 ml-4">
    <v-form
      id="iiotResourcesForm"
      ref="resources"
    >
      <v-row>
        <v-col
          cols="12"
          lg="4"
          class="pl-0 pt-0">
          <span>{{ $t("workloadVersion.resourcesTitle") }}</span> <br/>
          <limit-memory
            v-if="version.workloadProperties.limit_memory"
            ref="limitMemory"
            type="docker"
            :version="version"
            :prop="version.workloadProperties.limit_memory"
            @submit-event="triggerSubmit"
            :isReleased="isReleased"
          />
          <v-text-field
            id="iiotResourcesCpuResource"
            data-cy="iiotResourcesCpuResource"
            v-model.number="version.workloadProperties.limit_CPUs"
            :label="this.$t('workloadVersion.cpuResource')"
            :rules="[rules.range]"
            validate-on-blur
            @keydown.enter.prevent="triggerSubmit"
            :disabled="isReleased"
            :readonly="isReleased"
          >
          <v-tooltip
            slot="append"
            top
          >
            <template v-slot:activator="{ on }">
              <v-icon
                id="iiotResourcesInfoIcon"
                class="cursor-help mt-3"
                color="primary"
                v-on="on"
              >
                info
              </v-icon>
            </template>
            <span>{{ $t('workloadVersion.cpuResourceInfoMessage1') }}</span><br>
            <span>{{ $t('workloadVersion.cpuResourceInfoMessage2') }}</span><br>
          </v-tooltip>
          </v-text-field>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>

import LimitMemory from '@/components/workloads/dockerVmComponents/limitMemory/LimitMemory.vue';
import {
  CPU_RESOURCE,
} from '@/constants';

export default {
  components: { LimitMemory },
  data: () => ({
    CPU_RESOURCE,
  }),

  props: {
    version: {
      type: Object,
    },
    isReleased: {
      type: Boolean,
    },
  },

  computed: {
    rules() {
      return {
        range: (value) => (!value && value !== 0 ? true : (value > CPU_RESOURCE.MIN_LENGTH && value < CPU_RESOURCE.MAX_LENGTH) || this.$t('workloadVersion.cpuResourceRange')),
      };
    },
  },
  methods: {
    triggerSubmit() {
      if (this.$refs.resources.validate()) {
        this.$emit('submit-event');
      }
    },
  },

};
</script>
<style scoped>
  .iiotResourcesRow {
    height: 50vh;
  }
  #iiotResourcesInfoIcon {
    padding-right: 2px;
  }
</style>
