<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->

<template>
  <div>
    <codesys
      v-if="versionType === 'codesys'"
      :version="version"
    />
    <docker
      v-if="versionType === 'docker'"
      :version="version"
    />
    <vm
      v-if="versionType === 'vm'"
      :version="version"
    />
    <compose
      v-if="versionType === 'docker-compose'"
      :version="version"
    />
  </div>
</template>

<script>
import codesys from '@/components/workloads/Codesys.vue';
import docker from '@/components/workloads/Docker.vue';
import vm from '@/components/workloads/Vm.vue';
import compose from '@/components/workloads/Compose.vue';

export default {
  components: {
    codesys,
    docker,
    vm,
    compose,
  },
  data: () => ({
    versionType: '',
  }),
  computed: {
    version() {
      return this.$store.getters['workloads/getVersion'];
    },
  },
  mounted() {
    const route = window.location.pathname.split('/');
    this.versionType = route[route.length - 1];
  },
};
</script>
