<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->

<template>
  <v-container
    id="iiotVolumesContainer"
    class="mb-5 pt-7">
    <v-form
      id="iiotVolumesForm"
      ref="volumesForm">
      <v-row v-for="(volume, index) in prop"
        :key="index">
        <v-col
          cols="5"
          class="pa-0">
          <v-row class="ma-0">
            <v-text-field
              :id="`iiotVolumesNameInput_${index}`"
              :label="$t('workloadVersion.volume.volumeNameLabel')"
              v-model="volume.volumeName"
              validate-on-blur
              :rules="[rules.required, rules.patternName, rules.duplicateVolumeName]"
              @keydown.enter.prevent="triggerSubmit"
              :disabled="isReleased"
              @keyup="addToNameVolumeList(index, volume.volumeName)"
              @click="generateDockerVolumeName(index, volume.volumeName)"
              :minlength="VOLUMES_NAME.MIN_LENGTH"
              :maxlength="VOLUMES_NAME.MAX_LENGTH"
            />
          </v-row>
        </v-col>
       <v-row justify="center" class="pt-8">
          <span>:</span>
        </v-row>
        <v-col
          cols="5"
          class="pa-0">
          <v-text-field
            :id="`iiotVolumesPathInput_${index}`"
            v-model="volume.containerPath"
            :label="$t('workloadVersion.volume.volumePathLabel')"
            validate-on-blur
            :rules="[rules.required, rules.patternPath]"
            @keydown.enter.prevent="triggerSubmit"
            :disabled="isReleased"
            @keyup="addToNameVolumeList(index, volume.volumeName)"
            :minlength="VOLUMES_PATH.MIN_LENGTH"
            :maxlength="VOLUMES_PATH.MAX_LENGTH"
          />
        </v-col>
        <v-col
          cols="1"
          class="mt-4 pa-0 pl-3">
          <v-btn
            :id="`iiotVolumesRemoveButton_${index}`"
            fab
            x-small
            dark
            class="primary-tttech-background-btn"
            @click="removeVolume(index)"
            :disabled="isReleased"
          >
            <v-icon dark>
              mdi-minus
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="isFormValid">
        <div class="mb-1 ml-1">
          <v-btn
            id="iiotVolumesNewButton"
            fab
            x-small
            dark
            class="primary-tttech-background-btn"
            @click="addNewVolume()"
            :disabled="isReleased"
          >
            <v-icon dark>
              mdi-plus
            </v-icon>
          </v-btn>
        </div>
        <span class="mt-1 ml-3">
          {{this.$t('workloadVersion.volume.newVolume')}}
        </span>
      </v-row>
      <v-row v-if="prop && prop.length > 0">
        <v-col
          class="pa-0 mt-6"
          cols="12"
          lg="5">
          <v-select
            id="iiotVolumesNameSelect"
            data-cy="iiotVolumesNameSelect"
            v-model="selectedVolumeName"
            :menu-props="{ maxHeight: '150' }"
            attach
            :items="volumeNameList"
            :label="$t('workloadVersion.configurationStorage')"
            :disabled="isReleased"
            @change="setConfigurationStorage"
          />
        </v-col>
      </v-row>
      <v-row v-if="prop && prop.length > 0">
        <v-checkbox
          id="iiotVolumesRestartOnConfigurationUpdate"
          v-model="version.restartOnConfigurationUpdate"
          :label="$t('workloadVersion.restartOnConfUpd')"
          :disabled="isReleased
          || (selectedVolumeName === '' && !isRestartOnConfigurationUpdateDisabled)
          || selectedVolumeName === $t('workloadVersion.emptyState')"
          @change="checkboxClicked"
        />
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { VALIDATION_REGEX, VOLUMES_NAME, VOLUMES_PATH } from '@/constants';

export default {
  data: () => ({
    VALIDATION_REGEX,
    VOLUMES_NAME,
    VOLUMES_PATH,
    isFormValid: false,
    isRestartOnConfigurationUpdateDisabled: false,
  }),
  props: {
    prop: {
      type: Array,
    },
    version: {
      type: Object,
    },
    isReleased: {
      type: Boolean,
    },
  },
  computed: {
    rules() {
      return {
        required: (value) => !!value || this.$t('workloadVersion.required'),
        patternName: (value) => VALIDATION_REGEX.DOCKER_VOLUME_NAME.test(value) || this.$t('workloadVersion.volume.volumePattern'),
        patternPath: (value) => {
          if (VALIDATION_REGEX.DOCKER_VOLUME_PATH.test(value) && value[0] === '/') {
            return true;
          }
          return false || this.$t('errorMessages.nerve_workload_149');
        },
        duplicateVolumeName: (value) => {
          const found = this.version.workloadProperties.docker_volumes
            .filter((element) => element.volumeName === value);
          if (found.length > 1) {
            return false || this.$t('workloadVersion.volume.duplicateVolumeName');
          }
          return true;
        },
      };
    },
    volumeNameList() {
      return this.$store.getters['workloads/getVolumeNameList'];
    },
    selectedVolumeName: {
      get() {
        return this.$store.getters['workloads/getSelectedVolumeName'];
      },
      set() {},
    },
  },
  methods: {
    addNewVolume() {
      this.$refs.volumesForm.$el.dispatchEvent(new Event('input'));
      const obj = {
        volumeName: '',
        containerPath: '',
        configurationStorage: false,
      };
      this.prop.push(obj);
    },
    async removeVolume(index) {
      await this.prop.splice(index, 1);
      await this.volumeNameList.splice(index + 1, 1);
      this.$refs.volumesForm.$el.dispatchEvent(new Event('input'));
    },
    validationForm() {
      return this.$refs.volumesForm ? this.$refs.volumesForm.validate() : false;
    },
    triggerSubmit() {
      if (this.isFormValid) {
        this.$emit('submit-event');
      }
    },
    setConfigurationStorage(val) {
      this.isRestartOnConfigurationUpdateDisabled = val !== this.$t('workloadVersion.emptyState');
      this.$store.commit('workloads/SET_SELECTED_VOLUME_NAME', val);
      this.version.restartOnConfigurationUpdate = false;
      if (val === this.$t('workloadVersion.emptyState')) {
        this.$refs.volumesForm.$el.dispatchEvent(new Event('input'));
        // eslint-disable-next-line no-return-assign
        this.prop.forEach((volume) => volume.configurationStorage = false);
      }
      this.prop.forEach((item, index) => {
        if (item.volumeName === val) {
          this.prop[index].configurationStorage = true;
          this.$refs.volumesForm.$el.dispatchEvent(new Event('input'));
          return;
        }
        this.$refs.volumesForm.$el.dispatchEvent(new Event('input'));
        this.prop[index].configurationStorage = false;
      });
    },
    addToNameVolumeList(index, name) {
      if (this.isFormValid) {
        if (this.volumeNameList.length === 0) {
          this.volumeNameList.push(this.$t('workloadVersion.emptyState'));
        }
        if (this.volumeNameList[index] !== undefined) {
          if (this.volumeNameList.length === 2) {
            this.volumeNameList.splice(2, 1, name);
            return;
          }
          this.volumeNameList.splice(index + 1, 1, name);
          return;
        }
        this.volumeNameList.push(name);
        return;
      }
      this.$refs.volumesForm.$el.dispatchEvent(new Event('input'));
    },
    replaceInvalidSymbols(wlName) {
      const isValid = VALIDATION_REGEX.DOCKER_VOLUME_NAME_GENERATE_NAME.test(wlName);
      let newWlName = '';
      newWlName = isValid ? wlName : wlName.replace(/[^a-zA-Z0-9_.-]/g, '-').replace(/[-]+/g, '-');
      while (!newWlName.charAt(0).match(/[a-zA-Z0-9]/)) {
        newWlName = newWlName.substring(1);
      }
      return newWlName;
    },
    generateIndex() {
      let index = 1;
      const getWorkloadName = this.$store.getters['workloads/getWorkload'].name;
      const listVolumes = [];
      // eslint-disable-next-line max-len
      this.prop.forEach((volume) => listVolumes.push(volume.volumeName));
      const workloadName = this.replaceInvalidSymbols(getWorkloadName);
      while (listVolumes.includes(`${workloadName }_${index}`)) {
        index += 1;
      }
      return index;
    },
    generateDockerVolumeName(index) {
      const getWorkloadId = this.$store.getters['workloads/getWorkload']._id;
      const getWorkloadName = this.$store.getters['workloads/getWorkload'].name;
      const workloadName = this.replaceInvalidSymbols(getWorkloadName);
      if ((getWorkloadId === '' || getWorkloadId === 'new') && this.prop > 0) {
        return `${workloadName }_1`;
      }
      if (this.prop[index].volumeName === '') {
        this.prop[index].volumeName = `${workloadName }_${ this.generateIndex() }`;
      }
      return `${workloadName }_${ this.generateIndex() }`;
    },
    checkboxClicked() {
      this.$refs.volumesForm.$el.dispatchEvent(new Event('input'));
    },

  },
  mounted() {
    this.isFormValid = this.validationForm();
  },
  updated() {
    this.isFormValid = this.validationForm();
  },
};
</script>
<style>
  .volume-margin {
    margin-bottom: 140px;
  }
</style>
