<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->

<template>
  <div
    id="iiotUploadFileDocker"
    data-cy="iiotUploadFileDockerContainer"
    class="mb-5">
    <v-form
      id="iiotUploadFileDockerForm"
      data-cy="iiotUploadFileDockerForm"
      ref="uploadFileDockerForm">
      <v-col>
        <v-row>
          <v-col cols="10" class="pa-0">
            <v-text-field
              id="iiotUploadFileDockerTextField"
              v-model="dockerFile"
              :label="$t('workloadVersion.dockerLabel')"
              :placeholder="$t('workloadVersion.dockerPlaceholder')"
              :rules="[rules.required]"
              class="isRequired"
              disabled
              required
            />
          </v-col>
          <v-col cols="2">
            <v-btn
              id="iiotUploadFileDockerBtn"
              light
              color="primary"
              small
              fab
              @click="$refs.uploadFileDockerInput.click()"
              :disabled="isReleased"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon
                    class="white--text"
                    v-on="on"
                  >
                    arrow_upward
                  </v-icon>
                </template>
                <span>{{ $t('workloadVersion.uploadBtnTooltip') }}</span>
              </v-tooltip>
            </v-btn>
            <input
              id="iiotUploadFileDockerInput"
              ref="uploadFileDockerInput"
              type="file"
              style="display:none"
              :accept="allowedExtensionsForDocker"
              @click="clearInput"
              @change="importFile"
              :disabled="isReleased"
            >
          </v-col>
        </v-row>
      </v-col>
    </v-form>
  </div>
</template>

<script>

export default {
  data: () => ({
    allowedExtensionsForDocker: ['.tar.gz', '.tar'],
    dockerFile: '',
  }),
  props: {
    version: {
      type: Object,
    },
    isReleased: {
      type: Boolean,
    },
  },
  watch: {
    version() {
      this.$nextTick(() => {
        this.dockerFile = this.version.files ? this.version.files[0]?.originalName : '';
      });
    },
  },
  computed: {
    rules() {
      return {
        required: (value) => !!value || this.$t('workloadVersion.required'),
      };
    },
  },
  created() {
    const route = window.location.pathname.split('/');
    this.workloadId = route[route.length - 4];
    if (this.workloadId === 'new') {
      this.dockerFile = '';
      return;
    }
    this.dockerFile = this.version.files ? this.version.files[0]?.originalName : '';
  },
  methods: {
    importFile(e) {
      const file = e.target.files[0];
      const fileSize = file ? file.size / 1024 / 1024 : 0;
      if (file && file.name && !this.allowedExtensionsForDocker) {
        this.version.files = [{
          fieldName: 'file',
          originalName: file.name,
        }];
        this.dockerFile = file.name;
        this.$store.dispatch('workloads/save_uploaded_file', file);
        this.$refs.uploadFileDockerForm.$el.dispatchEvent(new Event('input'));
      } else if (file && file.name && this.allowedExtensionsForDocker) {
        if (fileSize === 0) {
          this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
            text: 'uploadFile.emptyFile',
            color: 'red',
            showClose: true,
          });
        } else if (fileSize >= 0
          // eslint-disable-next-line max-len
          && this.allowedExtensionsForDocker.find((ext) => file.name.toLowerCase().endsWith(ext))) {
          this.version.files = [{
            fieldName: 'file',
            originalName: file.name,
          }];
          this.dockerFile = file.name;
          this.$store.dispatch('workloads/save_uploaded_file', file);
          this.$refs.uploadFileDockerForm.$el.dispatchEvent(new Event('input'));
        } else {
          const extensionsArray = this.allowedExtensionsForDocker.slice();
          if (extensionsArray.length !== 1) {
            const a = extensionsArray[extensionsArray.length - 2] + this.$t('uploadFile.or') + extensionsArray[extensionsArray.length - 1];
            extensionsArray.splice(-2, 2);
            extensionsArray.push(a);
          }
          this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
            text: 'uploadFile.wrongFileExtension',
            textParameters: extensionsArray.join(', '),
            color: 'red',
            showClose: true,
          });
        }
      }
    },
    clearInput(e) {
      e.target.value = '';
    },
  },
};
</script>
<style>
  .v-text-field.v-input--is-disabled .v-input__control > .v-text-field__details > .v-messages {
    color: var(--v-error-base) !important;
  }
</style>
