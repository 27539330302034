<template>
  <div>
    <span>{{ $t('workloadVersion.dockerTitle') }}</span>
    <v-radio-group
      v-model="version.dockerFileOption"
      row
      @change="formHasChanged"
    >
      <v-radio
        id="iiotDockerImageFromRegistryOption"
        :label="$t('workloadVersion.DockerFromRegistryOption')"
        value="path"
        :disabled="isReleased"
      />
      <v-radio
        id="iiotDockerImageUploadOption"
        :label="$t('workloadVersion.DockerUploadOption')"
        value="file"
        :disabled="isReleased"
      />
    </v-radio-group>
    <div v-if="version.dockerFileOption === 'file'">
      <upload-file-docker
        ref="uploadFileDocker"
        :version="version"
        :isReleased="isReleased"
      />
    </div>
    <div v-if="version.dockerFileOption === 'path'">
      <from-registry
        ref="fromRegistry"
        :version="version"
        :isReleased="isReleased"
      />
    </div>
  </div>
</template>
<script>
import UploadFileDocker from '@/components/workloads/dockerVmComponents/upload-file/UploadFileDocker.vue';
import FromRegistry from '@/components/workloads/dockerVmComponents/upload-file/FromRegistry.vue';

export default {
  components: { UploadFileDocker, FromRegistry },
  props: {
    version: {
      type: Object,
    },
    type: {
      type: String,
    },
    isReleased: {
      type: Boolean,
    },
  },
  methods: {
    formHasChanged() {
      this.$store.dispatch('workloads/form_has_changed');
    },
  },
};
</script>
