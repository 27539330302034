<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->

<template>
  <v-container
    :id="`iiotLimitMemoryContainer_${type}`"
    :data-cy="`iiotLimitMemoryContainer_${type}`"
    class="mb-5"
    :class="[{'mt-3' : type !== 'snapshot', 'ml-3' : type === 'snapshot'},
    {'margin-bottom' : !isMemoryMarginVisible && type !== 'docker'}]"
    v-resize="onResize"
    >
    <v-form
      :id="`iiotLimitMemoryForm_${type}`"
      :data_cy="`iiotLimitMemoryForm_${type}`"
      ref="limitMemoryForm">
      <v-row>
        <v-col v-if="type==='docker'" cols="9" xs="8" class="pl-0">
          <v-row class="ma-0">
            <v-text-field
              :id="`iiotLimitMemoryInput_${type}`"
              :data-cy="`iiotLimitMemoryInput_${type}`"
              ref="limitMemoryInput"
              :label="$t('workloadManagement.limitMemory')"
              validate-on-blur
              :rules="[rules.required, rules.pattern, rules.minAndMax]"
              v-model.number="prop.value"
              @keydown.enter.prevent="triggerSubmit"
              :disabled="isReleased"
            />
          </v-row>
        </v-col>
        <v-col v-else cols="9" xs="8" lg="8" class="pl-0">
          <v-row class="ma-0">
            <v-text-field
              :id="`iiotLimitMemoryInput_${type}`"
              :data-cy="`iiotLimitMemoryInput_${type}`"
              ref="limitMemoryInput"
              :label="type === 'vm' ? $t('workloadManagement.limitMemoryRequired')
              : $t('workloadVersion.additionalDiskSpace')"
              validate-on-blur
              :rules="[rules.required, rules.pattern, rules.minAndMax]"
              v-model.number="prop.value"
              @keydown.enter.prevent="triggerSubmit"
              :disabled="isReleased"
            />
          </v-row>
        </v-col>
        <v-col v-if="type==='docker'" cols="3" xs="4" lg="3">
          <v-select
            :id="`iiotLimitMemorySelectedMeasure_${type}`"
            :data-cy="`iiotLimitMemorySelectedMeasure_${type}`"
            v-model="prop.unit"
            :items="measure"
            attach
            @change="onSelectedMeasureChange()"
            :disabled="isReleased"
          />
        </v-col>
        <v-col v-else cols="3" xs="4" lg="4">
          <v-select
            :id="`iiotLimitMemorySelectedMeasure_${type}`"
            :data-cy="`iiotLimitMemorySelectedMeasure_${type}`"
            v-model="prop.unit"
            :items="measure"
            attach
            @change="onSelectedMeasureChange()"
            :disabled="isReleased"
          />
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { MEASURES, VALIDATION_REGEX } from '@/constants';

export default {
  data: () => ({
    MEASURES,
    VALIDATION_REGEX,
    isFormValid: false,
    measure: [
      'KB',
      'MB',
      'GB',
      'TB',
    ],
    isMemoryMarginVisible: false,
  }),
  props: {
    type: {
      type: String,
    },
    prop: {
      type: Object,
    },
    version: {
      type: Object,
    },
    isReleased: {
      type: Boolean,
    },
  },
  computed: {
    rules() {
      return {
        required: (value) => {
          if (this.type === 'vm' || this.type === 'snapshot') {
            return !!value || this.$t('workloadVersion.required');
          }
          return true;
        },
        pattern: (value) => (value ? (VALIDATION_REGEX.MEMORY_INPUT.test(value) || this.$t('workloadVersion.memoryInputPatternMessage')) : true),
        minAndMax: (value) => {
          if (value && this.prop.unit) {
            const range = MEASURES.find((element) => element.measure === this.prop.unit);
            if (value > range.max) {
              return false || this.$t('workloadManagement.memoryMaxValueMessage', { value: range.max, measure: this.prop.unit });
            }
            if (value < range.min) {
              return false || this.$t('workloadManagement.memoryMinValueMessage', { value: range.min, measure: this.prop.unit });
            }
          }
          return true;
        },
      };
    },
  },
  mounted() {
    this.isFormValid = this.validationForm();
  },
  updated() {
    this.isFormValid = this.validationForm();
  },
  methods: {
    onSelectedMeasureChange() {
      this.isFormValid = this.validationForm();
      this.$refs.limitMemoryForm.$el.dispatchEvent(new Event('input'));
    },
    validationForm() {
      return this.$refs.limitMemoryForm ? this.$refs.limitMemoryForm.validate() : false;
    },
    triggerSubmit() {
      if (this.isFormValid) {
        this.$emit('submit-event');
      }
    },
    onResize() {
      this.isMemoryMarginVisible = window.innerWidth > 1264;
    },
  },
};
</script>
<style scoped>
  .margin-bottom {
    margin-bottom: 130px !important;
  }
</style>
