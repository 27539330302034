<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->

<template>
  <v-container
    id="iiotCodesysContainer"
    fill-height
    fluid
    class="pl-0 pt-0"
  >
    <v-row>
      <v-col>
        <v-row>
          <v-col
            cols="12"
            lg="11">
            <div v-if="!isWorkloadEmpty" class="title">
              <h1>
                {{ isNewWl  ? $t('workloadVersion.newWl') : (isUpdate ?
                $t('workloadVersion.editWl') :  isCloneWl ?
                $t('workloadVersion.cloneWl') :  $t('workloadVersion.title')) }}
              </h1>
              <v-divider />
            </div>
          </v-col>
        </v-row>
        <v-form
          v-if="!isWorkloadEmpty && isVersionReady"
          id="iiotCodesysForm"
          data-cy="iiotCodesysForm"
          ref="form"
          @submit.prevent="submitForm"
        >
        <v-col class="pl-0 mb-10">
          <v-row>
            <v-col>
              <v-tabs
                v-model="tab"
                class="tab-text-transform"
                next-icon="mdi-arrow-right-bold-box-outline"
                prev-icon="mdi-arrow-left-bold-box-outline"
                show-arrows>
                <v-tab
                  id="iiotCodesysBasicTab"
                  data-cy="iiotCodesysBasicTab"
                  autofocus
                  :key="basic"
                  @click="tabChanged(0)"
                >
                  <h4 v-if="isBasicFormValid">
                    {{ $t('workloadVersion.basic') }}</h4>
                  <h4
                    v-if="!isBasicFormValid"
                    class="required">
                    {{ $t('workloadVersion.requiredBasic') }}
                  </h4>
                </v-tab>

                <v-tab
                  id="iiotCodesysRemoteConnectionTab"
                  data-cy="iiotCodesysRemoteConnectionTab"
                  :key="remoteConnections"
                  @click="tabChanged(1)"
                >
                  <h4>{{ $t('workloadVersion.remoteConnections') }}</h4>
                </v-tab>
              </v-tabs>
              <v-divider />
            </v-col>
          </v-row>
          <v-tabs-items
            v-model="tab">
            <v-tab-item
              :key="basic">
              <basic
                ref="basic"
                type="codesys"
                :version="version"
                @submit-event="submitEventHandler"
                :isReleased="isReleased"
              />
            </v-tab-item>

            <v-tab-item
              eager
              :key="remoteConnections">
              <v-col
                cols="12"
                lg="4">
                <remote-connection-list
                id="iiotCodesysList"
                :model="version"
                :version-type="'codesys'"
              />
              </v-col>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
        <v-col class="pl-0 pr-0">
          <v-row>
            <v-col>
              <div class="fixed-buttons">
                <nerve-button
                  id="iiotCodesysCancelButton"
                  :text="$t('baseForm.cancelBtn')"
                  type-of-btn="cancel"
                  size="normal"
                  class="mr-5 ml-0"
                  @click-event="cancel()"
                />
                <nerve-button
                  v-if="(!isUpdate && !canAccess('UI_WORKLOAD:VERSION_EDIT'))
                  || (isUpdate && canAccess('UI_WORKLOAD:VERSION_EDIT'))
                  || (!isUpdate && canAccess('UI_WORKLOAD:VERSION_CREATE'))"
                  id="iiotCodesysSaveButton"
                  :disabled="!isSaveEnabled || !isValid"
                  :text="$t('baseForm.saveBtn')"
                  type-of-btn="action"
                  size="normal"
                  type="submit"
                />
              </div>
            </v-col>
          </v-row>
        </v-col>

        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>
<!-- eslint-disable no-unused-expressions -->
<script>
import { NerveButton } from 'nerve-ui-components';
import RemoteConnectionList from '@/components/remote-connection/RemoteConnectionList.vue';
import Basic from '@/components/workloads/workloadVersionComponents/Basic.vue';
import Logger from '@/utils/logger';

export default {
  components: {
    RemoteConnectionList,
    Basic,
    NerveButton,
  },
  data: () => ({
    isUpdate: false,
    tab: null,
    basic: 0,
    remoteConnections: 1,
    workloadId: '',
    isBasicFormValid: true,
    availableTabs: [0, 1],
    createAvailableTabs: [0],
    versionId: '',
    isWorkloadEmpty: false,
    onCreated: false,
    isReleased: false,
    isValid: false,
    isVersionReady: false,
    isNewWl: false,
    isCloneWl: false,
  }),
  watch: {
    async isSaveEnabled(newValue) {
      if (newValue) {
        this.isValid = await this.isCodesysFormValid();
      }
    },
    getFormChangedFlag() {
      this.formChanged();
    },
  },
  async created() {
    try {
      const route = window.location.pathname.split('/');
      this.versionId = route[route.length - 2];
      if (this.versionId === 'clone') {
        this.isCloneWl = true;
      }
      this.workloadId = route[route.length - 4];
      this.isUpdate = this.versionId !== 'new' && this.versionId !== 'clone';
      const tab = Number(this.$route.query.tab);
      const tabs = this.isUpdate ? this.availableTabs : this.createAvailableTabs;
      if (tabs.includes(tab)) {
        this.tabChanged(Number(this.$route.query.tab));
      } else {
        this.tabChanged(0);
      }
      this.version.released = false;
      if (this.versionId !== 'clone') {
        await this.$store.dispatch('workloads/get_workload_by_id', this.workloadId);
      } else {
        if (Object.keys(this.version).length === 0 && this.version.constructor === Object) {
          this.cancel();
        }
        if (!this.version.name) {
          this.cancel();
        }

        this.$store.dispatch('workloads/set_save_enabled', this.version);

        this.isVersionReady = true;
        // Add listeners for changes on all forms
        this.$nextTick(() => {
          this.addListeners();
        });
        return;
      }
      this.workload.type = 'codesys';
      await this.$store.dispatch('workloads/get_version_by_id', { id: this.versionId, isUpdate: this.isUpdate });
      this.isVersionReady = true;
      if (this.versionId === 'new') {
        this.version.released = false;
        this.isNewWl = true;
      }
      this.isReleased = this.version.released;

      // Add listeners for changes on all forms
      this.$nextTick(() => {
        this.addListeners();
      });

      if (this.getNameAndDesc.name === '' && this.workload.name === '') {
        this.cancel();
      }
      if (this.getNameAndDesc.name === '' && this.workload.name !== '') {
        return;
      }
      if (this.getNameAndDesc.name !== '' && this.workload.name === '') {
        this.workload.name = this.getNameAndDesc.name;
        this.workload.description = this.getNameAndDesc.description;
        return;
      }
      if (this.workload.name !== this.getNameAndDesc.name && this.workload.name !== '') {
        this.workload.name = this.getNameAndDesc.name;
        this.workload.description = this.getNameAndDesc.description;
      }
    } catch (e) {
      Logger.error(e);
    }
  },
  async updated() {
    await this.$store.dispatch('remote-connection/fetch', this.version);
  },

  beforeDestroy() {
    this.$store.dispatch('workloads/disable_save_button');
  },

  computed: {
    version() {
      return this.$store.getters['workloads/getVersion'];
    },
    workload() {
      return this.$store.getters['workloads/getWorkload'];
    },
    getNameAndDesc() {
      return this.$store.getters['workloads/getNameAndDesc'];
    },
    isSaveEnabled() {
      return this.$store.getters['workloads/isSaveEnabled'];
    },
    getFormChangedFlag() {
      return this.$store.getters['workloads/getFormChangedFlag'];
    },
  },
  methods: {
    async submitForm() {
      if (!this.isCodesysFormValid()) {
        return;
      }
      if (this.version.released) {
        this.$store.dispatch('utils/_api_request_handler/show_confirm_dialog', {
          title: 'workloadVersion.markAsReleasedMessageTitle',
          subTitle: 'workloadVersion.markAsReleasedMessageText',
          callback: async () => {
            this.createWorkload();
          },
        });
      } else {
        this.createWorkload();
      }
    },

    async createWorkload() {
      const isAddVersionOnExistingWl = !this.isUpdate && this.version._id;
      if (isAddVersionOnExistingWl) {
        delete this.version._id;
      }
      if (!this.workload.versions.length) {
        this.version.files = {
          0: {
            originalName: this.version.files[0].originalName,
          },
        };
      }
      const fd = new FormData();
      this.version.dockerFileOption = '';
      this.workload.versions = [this.version];
      this.workload.type = 'codesys';
      fd.append('data', JSON.stringify(this.workload));
      fd.append('file', this.$store.getters['workloads/getUploadedFile']);
      try {
        if (!this.workload._id) {
          await this.$store.dispatch('workloads/create_workload', fd);
        } else {
          await this.$store.dispatch('workloads/update_version', fd);
        }
        await this.$store.dispatch('workloads/save_uploaded_file', {});
        this.$store.dispatch('utils/_api_request_handler/close_progress_bar');
      } catch (e) {
        this.$store.dispatch('utils/_api_request_handler/close_progress_bar');
        return;
      }

      if (this.workloadId === 'new') {
        await this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
          text: 'workloadVersion.successfullyCreated',
          color: 'green',
          showClose: true,
        });
        this.$router.push({
          name: 'Workloads',
        });
        return;
      }
      await this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
        text: 'workloadDetail.successfullyUpdate',
        color: 'green',
        showClose: true,
      });
      this.$router.push({
        name: 'Add edit workload',
        params: { id: this.workloadId, type: 'codesys' },
      });
    },

    async isCodesysFormValid() {
      this.isBasicFormValid = await this.$refs.basic.$refs.basicForm.validate()
      && await this.$refs.basic.$refs.versionUploadImage.$refs.uploadFileCodesys
        .$refs.uploadFileCodesysForm.validate();
      return this.isBasicFormValid;
    },
    async tabChanged(tab) {
      this.tab = tab;
      await this.$router.push({
        name: 'Add edit workload version',
        query: {
          tab,
        },
      }).catch(() => {});
    },
    cancel() {
      this.$store.dispatch('workloads/save_uploaded_file', {});
      this.$router.push({ name: 'Add edit workload', params: { id: this.workloadId, type: 'codesys' } });
    },
    submitEventHandler() {
      this.submitForm();
    },
    async formChanged() {
      this.isValid = await this.isCodesysFormValid();
      if (this.isValid) {
        this.$store.dispatch('workloads/set_save_enabled', this.version);
        return;
      }
      this.$store.dispatch('workloads/disable_save_button');
    },
    addListeners() {
      this.$refs.basic?.$refs.basicForm?.$el.addEventListener('input', () => {
        this.formChanged();
      });
      this.$refs.basic?.$refs.versionUploadImage?.$refs.uploadFileCodesys?.$refs
        .uploadFileCodesysForm?.$el.addEventListener('input', () => {
          this.formChanged();
        });
    },
  },
};
</script>

<style scoped>
  .fixed-buttons {
    position: fixed !important;
    bottom: 0px;
    background: white;
    z-index: 10;
    padding-bottom: 10px;
    padding-right: 10px;
  }
  .required {
    color: #701825;
  }
</style>
